.footer {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.footer-content {
    background-color: rgb(36,36,36);
    padding: 50px;
}

.row {
    display: flex;
    /* color: rgb(255, 255, 255); */
}

.row:before,
.row:after {
    content: " ";
    display: table;
}



.footer-logo {
    width: 80px;
}


.footer-text {
    margin-top: 28px;
    color: white;
    line-height: 28px;
    margin-bottom: 5px;

}

.footer-link {
    color: white;
    text-decoration: none;
}

.column {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

h6 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: Montserrat, sans-serif;
    color: #7d7d7d;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}


@media (max-width: 479px) {

    .footer-content {
        text-align: center;
        display: flex;

    }

    .column {
        width: 100%;

    }

    .row {
        display: block;
    }


}

@media (max-width: 767px) {

    .footer {
        display: inline-block;
    }

    .footer-content {
        padding: 20px;
        text-align: center;
        display: flex;

    }

    .footer-logo {
        margin-bottom: 26px;
    }

    .row {
        background-color: rgb(36,36,36);
        display: block;
        margin-left: 0;
        margin-right: 0;
    }


    .column {
        width: 100%;
        left: auto;
        right: auto;
    }

    .footer-text {
        margin-top: 15px;
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 991px) {
    .footer-logo {

        width: 75px;
    }
}