.contact-section {
    height: auto;
    min-height: 400px;
    margin-bottom: 0px;
    padding: 50px 20px 0px;
}


h5 {
    margin-top: 5px;
    margin-bottom: 30px;
    font-family: Vollkorn, serif;
    font-size: 16px;
    line-height: 22px;
    font-style: italic;
    font-weight: 400;
}

.row-column1 {
    position: relative;
    width: 50%;
    text-align: center;
    min-height: 1px;
    margin: 0 25%;
    padding-left: 10px;
    padding-right: 10px;
}

.input-field {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    letter-spacing: -1px;
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    line-height: 17px;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
}

.label {
    font-family: Montserrat, sans-serif;
    letter-spacing: -1px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.contact-form {
    margin-top: 100px;
    margin-bottom: 100px;
}

.button-field {
    display: inline-block;
    margin-top: 30px;
    padding: 15px 35px;
    border: 2px solid black;
    background-color: transparent;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    font-family: Montserrat, sans-serif;
    color: #333;
    font-size: 12px;
    text-align: center;
    letter-spacing: -1px;
    text-decoration: none;
}



.contact-row::before,
.contact-row::after {
    content: " ";
    display: table;
}

@media screen and (max-width:479px) {
    .contact-section {
        margin-top: 30px;
        padding-top: 30px;
    }

    .row-column1 {
        width: 100%;
    }


    h5 {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 21px;
    }
}

@media screen and (max-width: 767px) {
    .row-column1 {
        width: 100%;
        left: auto;
        right: auto;
        float: left;
        margin: 0;
    }

    .contact-form {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}


@media screen and (max-width:991px) {

    h5 {
        margin-bottom: 15px;
        font-size: 15px;
    }

    .button-field {
        margin-top: 15px;
    }
}