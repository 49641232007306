.quotes-section {
    padding: 100px 20px;
    text-align: center;
}

.quotes-section-text {
    height: 100px;
    background-color: white;
    position: relative;
    height: 300px;
    text-align: center;
    background: rgba(153, 153, 153, 0.5);
    clear: both;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}

.quotes-section-slider {
    position: relative;
    text-align: center;
    clear: both;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100px;
    background-color: white;
}



.quotes-section-slider-mask {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    left: 0;
    right: 0;
    height: 100%;
    white-space: nowrap;
    text-align: center;
}

.quote-1 {
    padding-top: 30px;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
}



.quote-text {
    font-family: 'Source Sans Pro', sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1.5px;
}

@media screen and (max-width:479px) {
    .quotes-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }


    h5 {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 21px;
    }

    .quote-text {
        font-size: 16px;
        line-height: 24px;
    }

    /* .quotes-section-slider {
        height: 130px;
    } */

}

@media screen and (max-width:767px) {
    .quotes-section {
        padding: 0 25%;
        display: inline-block;
    }

    .quotes-section-slider {
        display: none;
    }
}

@media (max-width: 991px) {
    .quotes-section {
        padding-top: 30px;
        padding-bottom: 50px;
    }


    h5 {
        margin-bottom: 15px;
        font-size: 15px;
    }

    .quote-text {
        font-size: 17px;
        line-height: 26px;
        letter-spacing: -1px;
    }
}