.agency-navbar {
    display: flex;
    position: relative;
    margin: 0 76.8px;
    z-index: 1000;

    width: 85%;
    /* max-width: 2000px; */
    margin-right: auto;
    margin-left: auto;
}


.navbar-section {
    position: absolute;
    left: 0px;
    top: 34px;
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: transparent;
}

.navbar-section::before,
.navbar-section::after {
    display: table;
    content: "";
}

.agency-navbar img {
    margin-right: 70px;
    height: 60px;
}

.agency-navbar-links a {
    padding-right: 35px;
    padding-left: 35px;
    /* float: none; */
    color: white;
    font-size: 13px;
    letter-spacing: -1px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    font-family: Montserrat, sans-serif;

}


/* *********************** menu ***********
.nav {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: black;
    overflow: hidden;

}

.menu a {
    text-align: center;
    display: block;
    padding: 30px;
    color: #fff;
}

.menu a:hover {
    background-color: gray;
}

.nav {
    max-height: 0;
    transition: max-height .5s ease-out;
}

.hamb {
    cursor: pointer;
    float: right;
    padding: 40px 20px;
    display: none;
}

.hamb-line {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    width: 24px;

}


.hamb-line::before,
.hamb-line::after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.hamb-line::before {
    top: 5px;
}

.hamb-line::after {
    top: -5px;
}

.side-menu {
    display: none;
}

.side-menu:checked~nav {
    max-height: 100%;
}

.side-menu:checked~.hamb .hamb-line {
    background: transparent;
}

.side-menu:checked~.hamb .hamb-line::before {
    transform: rotate(-45deg);
    top: 0;
}

.side-menu:checked~.hamb .hamb-line::after {
    transform: rotate(45deg);
    top: 0;
}
 */

/******************** ********************/

@media (max-width: 767px) {
    .agency-navbar img {
        margin-left: 0px;
    }
}

@media (max-width: 479px) {
    .agency-navbar img {
        margin-left: 5px;
    }
}

/* 
    .nav {
        max-height: none;
        top: 0;
        position: relative;
        float: right;
        width: fit-content;
        background-color: transparent;
    }

    .menu li {
        float: left;
    }

    .menu a:hover {
        background-color: transparent;
        color: gray;

    }

    .hamb {
        display: none;
    } */

@media screen and (max-width: 991px) {
    .menu-button {
        display: block;
    }

    .agency-navbar-links {
        display: none;
    }

    .agency-navbar-links {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        padding-top: 100px;
        background-color: rgb(247, 247, 247);
        text-align: center;

    }

    /* .mobile-logo {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    .w-nav[data-collapse="medium"] .w-nav-button {
        display: block;
    }

    .menu-button {
        position: absolute;
        top: 0px;
        right: 19px;
        z-index: 0;
        margin-top: 30px;
        padding-top: 10px;
    }

    .nav-icon {
        color: white;
    }

    .agency-navbar img {
        width: 40px;
        margin-left: 5px;
        padding-top: 30px;
    }

    .hamb-line{
        display:inline-block;
    } */


}