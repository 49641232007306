.header-section {
  padding: 20px;
}

.slide1,
.slide2,
.slide3 {

  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 95vh;
}

.slide-container {
  display: block;
  width: 75%;
  margin-top: 275px;
  margin-right: auto;
  margin-left: auto;
  text-align: right;
}


h1 {
  margin-top: -194px;
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  color: white;
  font-size: 56px;
  line-height: 54px;
  font-weight: 700;
  letter-spacing: -3px;
}

h4 {

  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Vollkorn, serif;
  color: white;
  font-size: 17px;
  line-height: 22px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0px;
}

path {
  fill: white;
}

.slide1,
.slide2 {
  background-position: 50% 0px;
}

.slide3 {
  background-position: 50% 100%;
}


.default-nav {
  background-color: red;
}

.react-slideshow-container .default-nav:first-of-type,
.react-slideshow-container .default-nav:last-of-type {
  background: none;
}

.react-slideshow-container .default-nav:first-of-type:hover,
.react-slideshow-container .default-nav:last-of-type:hover {
  background: none;
}


@media screen and (max-width:467px) {
  .slide-container {
    margin-top: 130px;
  }

  h1 {
    font-size: 28px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .slide-container {
    margin-top: 100px;
  }
}

@media screen and (max-width:990px) {
  .react-slideshow-container .default-nav:first-of-type {
    display: none;
  }

  h1 {
    margin-bottom: 5px;
    font-size: 48px;
    line-height: 46px;
  }



  .react-slideshow-container .default-nav:last-of-type {
    display: none;
  }

  .slide-container {
    width: 85%;
    margin-top: 250px;
  }

}